export default {
    fields: [
        {
            heading: 'Company',
            name: 'heading_main',
            hint: 'Please provide public information about your company. Some of this information is required by law in order for you to contact new customers. <br/><small class="d-block fs-6 pt-3">Estimated completion time: <span class="text-warning fw-bold">5 minutes</span>.</small>',
            component: 'Header',
            /**
            * classes such us h(1-5) or display-(1-5) will be added to the heading tag
            */
            classes: 'pt-4 h2',
            label: '',
            placeholder: '', 
            value: '',
        }, 
        {
            heading: 'GENERAL INFORMATION',
            label: '',
            name: 'heading_general_info',
            placeholder: '', 
            hint: 'Your company\'s contact information.',
            value: '',
            component: 'Heading',
            classes: 'pt-4 h4'
        }, 
        {
            heading: 'What is the name of the Company?',
            label: 'Company Name',
            name: 'name',
            placeholder: '', 
            hint: '( Your company\'s friendly name )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'What is the legal name of the Company?',
            label: 'Company Legal Name',
            name: 'legalName',
            placeholder: '', 
            hint: '( Your company\'s legal name. E.g. "Company LLC" )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'What phone number should be used?',
            label: 'Company Phone',
            name: 'phone',
            placeholder: '', 
            hint: '( Your company"s phone number. e.g. "(212) 555-0000" )',
            value: '',
            component: 'InputText'
        },
        {
            heading: 'What email address should be used for the company?',
            label: 'Company Email',
            name: 'email',
            placeholder: '', 
            hint: '( Your company"s email address. e.g. "employee@company.com" )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'What website URL should be used for the company?',
            label: 'Company Website',
            name: 'website',
            placeholder: '', 
            hint: '( Your company"s website URL. e.g. "https://company.com" )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'What is the company"s mailing address?',
            label: 'Company Address',
            name: 'address',
            placeholder: '', 
            hint: '( Your company"s address. e.g. "123 Main Ave." )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'In which cty is the company located?',
            label: 'Company City',
            name: 'city',
            placeholder: '', 
            hint: '( The name of the city where the company is located. e.g. "Chicago" )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'In which state is the company located?',
            label: 'Company State',
            name: 'state',
            placeholder: '', 
            hint: '( The state where the company is located. e.g. "Illinois" )',
            value: '',
            component: 'SelectState'
        }, 
        {
            heading: 'What zip code should be used for the company?',
            label: 'Company Zip Code',
            name: 'zip',
            placeholder: '', 
            hint: '( The company\'s postal code. e.g. "12345" )',
            value: '',
            component: 'InputNumber'
        }, 
        {
            heading: 'In which country is the company located?',
            label: 'Company Country',
            name: 'country',
            placeholder: '', 
            hint: '( The country where your company is located. e.g. "USA" )',
            value: '',
            component: 'SelectCountry'
        },


        /* ONLINE PRESENCE HEADING */
        {
            heading: 'ONLINE PRESENCE',
            label: '',
            name: 'heading_online_presence',
            placeholder: '', 
            hint: 'Websites that showcase your company and link back to your website.',
            value: '',
            component: 'Heading',
            /**
                * classes such us h(1-5) or display-(1-5) will be added to the heading tag
                */
            classes: 'pt-4 h4'
        }, 

        {
            heading: 'What is the Facebook Page URL?',
            label: 'Facebook Page URL',
            name: 'facebook_page',
            placeholder: '', 
            hint: '( e.g. "https:/facebook.com/yourpagename" )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'What is THE Facebook Group URL?',
            label: 'Facebook Group URL',
            name: 'facebook_group',
            placeholder: '', 
            hint: '( e.g. "https:/facebook.com/groups/yourgroupname" )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'What is the Instagram Profile URL?',
            label: 'Instagram Profile URL',
            name: 'instagram',
            placeholder: '', 
            hint: '( e.g. "https://www.instagram.com/yourusername" )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'What is your Twitter username?',
            label: 'Twitter Username',
            name: 'twitter',
            placeholder: '', 
            hint: '( e.g. "yourusername" )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'What is your LinkedIn profile URL?',
            label: 'LinkedIn Profile URL',
            name: 'linkedin',
            placeholder: '', 
            hint: '( e.g. "https:/linkedin.com/in/yourusername" )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'What is your Youtube Channel URL?',
            label: 'Youtube Channel URL',
            name: 'youtube',
            placeholder: '', 
            hint: '( e.g. "https://www.youtube.com/channel/UCF0pVplsI8R5kcAqgtoRqoA" )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'What is the company\'s Yelp Profile URL?',
            label: 'Yelp Profile URL',
            name: 'yelp',
            placeholder: '', 
            hint: '( e.g. "https://www.yelp.com/biz/yourbusinessname" )',
            value: '',
            component: 'InputText'
        }, 
        {
            heading: 'What is the Google Business Profile URL?',
            label: 'Google Business Profile URL',
            name: 'google_business_profile',
            placeholder: '', 
            hint: '( e.g. "https://www.google.com/search?q=Appcropolis" )',
            value: '',
            component: 'InputText'
        }, 
        /** START: input group */
        {
            heading: 'What other public URLs are important for the company?',
            label: 'Other URL 1',
            name: 'other_url_1',
            placeholder: '', 
            hint: '( e.g. "https://otherurl-1.com" )',
            value: '',
            component: 'InputText',
            classes: 'pt-4'
        }, 
        {
            heading: '',
            label: 'Other URL 2',
            name: 'other_url_2',
            placeholder: '', 
            hint: '( e.g. "https://otherurl-2.com" )',
            value: '',
            component: 'InputText',
            classes: 'py-0'
        }, 
        {
            heading: '',
            label: 'Other URL 3',
            name: 'other_url_3',
            placeholder: '', 
            hint: '( e.g. "https://otherurl-3.com" )',
            value: '',
            component: 'InputText',
            classes: 'pb-4'
        }, 
        /** END: input group */
        {
            heading: '',
            label: 'Mark as complete',
            name: 'done',
            placeholder: '', 
            hint: '',
            value: false,
            component: 'CustomDone',
            classes: 'pt-4',
            readonly: false
        }, 
    ]
}