export default {
    fields: [
        {
            heading: 'Preferences',
            name: 'user_preferences',
            hint: 'Customize the application to make it fit you workflow preferences.',
            component: 'Header',
            /**
            * classes such us h(1-5) or display-(1-5) will be added to the heading tag
            */
            classes: 'pt-4 h2',
            label: '',
            placeholder: '', 
            value: '',
            readonly: false,
        }, 
        {
            heading: 'USER INTERFACE',
            label: '',
            name: 'ui_preferences',
            placeholder: '', 
            hint: 'Adjust parameters to inprove your user interface and user experience.',
            value: '',
            component: 'Heading',
            classes: 'pt-4 h4'
        }, 
        {
            heading: 'Adjust the application to better fit your screen',
            label: 'Screen Size',
            name: 'screen_size',
            placeholder: '', 
            hint: '( e.g. "Small - 80%" if your are working on a laptop )',
            value: '',
            component: 'SelectScreenSize'
        }, 
    ]
}