<template>
    <div :class="[`field-${name}`, classes? classes : 'py-4']">
        <div class="form-group mb-0 row">
            <div class="col-md-9 offset-md-3">
                <h2 class="fw-normal mb-2" :class="[headingClass]" v-html="heading"></h2>
                <p class="text-muted lead" v-html="hint"></p>
            </div>
        </div>
    </div>
</template>


<script>

import common from "./Common.js";

export default {
    name: "Heading",
    props: common.props, 

    computed: {
        headingClass() {
            let headingClass = 'h2';

            if(/(h|H)\d/.test(this.classes)) {
                for(let i=0; i<[1, 2, 3, 4, 5].length; i++) {
                    let found = this.classes.match(/\b(h|H)(1|2|3|4|5)/)?.[0];
                    if( found ) {
                        headingClass = found;
                        break;
                    }
                }
            }

            if(/display-\d/.test(this.classes)) {
                for(let i=0; i<[1, 2, 3, 4, 5].length; i++) {
                    let found = this.classes.match(/\bdisplay-(1|2|3|4|5)/)?.[0];
                    if( found ) {
                        headingClass = found;
                        break;
                    }
                }
            }

            return headingClass;
        }
    }
};
</script>