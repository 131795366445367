<template>
    <div :class="[`field-${name}`, classes? classes : 'py-4']">
        <div class="row">
            <div class="col-md-9 offset-md-3">
                <button
                    @click="done"
                    :id="name"
                    :class="[val === true? 'complete' : '', readonly? 'disabled pointer-events-none text-muted' : '']"
                    class="btn btn-done btn-lg px-3 position-relative"
                >
                    <span class="material-icons me-2 mr-3 mt-1 pull-left" v-if="val">
                        check_box
                    </span>
                    <span class="material-icons me-2 mr-3 mt-1 pull-left" v-if="!val">
                        check_box_outline_blank
                    </span>
                    <span class="btn-label mr-2">
                        {{ label }}
                    </span>
                </button>
            </div>
            <div class="offset-md-3 col-md-9">
                <p class="pl-md-2 text-muted">{{ hint }}</p>
            </div>
        </div>
    </div>
</template>

<script>

import common from "./Common.js";

export default {
    name: "CustomDone",
    props: common.props,
    computed: {
        val: {
            get: function() {
                let bool = this.value == 'true' || this.value == true? true : false;
                return bool;
            },
            set: function(newValue) {
                this.update(this.name, newValue);
            },
        },
    },

    methods: {
        done() {
            this.val = !this.val;
        }
    }
};
</script>



<style scoped>
.btn-done {
    background-color: var(--bg-secondary);
}
</style>