<template>
    <div :class="[`field-${name}`, classes? classes : 'py-4']">
        <div class="row">
            <h4 v-if="heading.length" class="col-md-9 offset-md-3 fw-normal">{{ heading }}</h4>
            <label
                :for="name"
                class="col-md-3 col-form-label col-form-label-lg text-md-right pl-md-0"
            >{{ label }}</label>

            <div class="col-md-9">
                <button class="px-5 py-2 btn btn-primary fs-5 d-inline"
                    :class="[readonly? 'disabled pointer-events-none text-muted' : '']"
                    :id="name"
                    :name="name"
                    :placeholder="placeholder"
                    @click="action">{{ val }}</button>
            </div>
            <div class="offset-md-3 col-md-9">
                <p class="pl-md-2 text-muted">{{ hint }}</p>
            </div>
        </div>
    </div>
</template>

<script>

import common from "./Common.js";

export default {
    name: "ButtonPassword",
    props: common.props,
    computed: common.computed,
    methods: {
        action() {
            this.$root.updatePasswordIntent()
        }
    }
};
</script>
