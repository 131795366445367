<template>
    <form @submit.prevent="">
        <component v-for="(field, index) in fields" :key="index" :is="field.component"
            :heading="field.heading"
            :label="field.label"
            :name="field.name"
            :value="field.value"
            :placeholder="field.placeholder"
            :hint="field.hint"
            :classes="field.classes"
            :readonly="field.readonly"
            :update="updateSingleField"
        ></component>
    </form>
</template>

<script>

import CustomDone from "./fields/CustomDone.vue";
import ButtonController from "./fields/ButtonPassword.vue";
import InputText from "./fields/InputText.vue";
import InputPassword from "./fields/InputPassword.vue";
import InputNumber from "./fields/InputNumber.vue";
import Header from "./fields/Header.vue";
import Heading from "./fields/Heading.vue";
import SelectState from "./fields/SelectState.vue";
import SelectCountry from "./fields/SelectCountry.vue";
import SelectScreenSize from "./fields/SelectScreenSize.vue";

export default {
    name: "FormContainer",

    components: {
        CustomDone,
        ButtonController,
        InputText,
        InputPassword,
        Header,
        Heading,
        InputNumber,
        SelectState,
        SelectCountry,
        SelectScreenSize,
    },

    props: {
        form: Object, 
        update: Function
    },

    data() {
        return {
        };
    },

    computed: {
        fields() {
            return this.form?.fields;
        }
    },

    methods: {

        updateSingleField(name, value) {
            this.update(name, value);
        }
    },



    mounted() {


    },
};
</script>

<style scoped></style>
