export default {
    fields: [
        {
            heading: 'Keys',
            name: 'user_keys',
            hint: 'In this section you can store you external applications keys (e.g. OpenAI).',
            component: 'Header',
            /**
            * classes such us h(1-5) or display-(1-5) will be added to the heading tag
            */
            classes: 'pt-4 h2',
            label: '',
            placeholder: '', 
            value: '',
            readonly: false,
        }, 
        {
            heading: 'KEYS',
            label: '',
            name: 'user_keys_label',
            placeholder: '', 
            hint: 'Enter your application\'s secret key(s).',
            value: '',
            component: 'Heading',
            classes: 'pt-4 h4'
        }, 
        {
            heading: 'Enter your OpenAI key',
            label: 'OpenAI Key',
            name: 'openai_key',
            placeholder: '', 
            hint: '( e.g. "khys87jd9..." )',
            value: '',
            component: 'InputPassword'
        }, 
    ]
}