<template>
    <div :class="[`field-${name}`, classes? classes : 'py-4']">
        <div class="form-group mb-0 row">
            <div class="col-md-3">
                <svg :style="{ width: '100%'}" width="200" height="200" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="127" height="127" rx="15.5" fill="white" stroke="#EBEBEB"/>
                    <rect x="11.5" y="11.5" width="105" height="105" rx="7.5" stroke="#E4E6EB" stroke-dasharray="3 3"/>
                    <line x1="5.35355" y1="5.64645" x2="122.354" y2="122.646" stroke="#E4E6EB" stroke-dasharray="3 3"/>
                    <line x1="4.64645" y1="122.646" x2="122.646" y2="4.64645" stroke="#E4E6EB" stroke-dasharray="3 3"/>
                </svg>
            </div>

            <div class="col-md-9">
                <h2 class="fw-normal mb-2" :class="[headingClass]" v-html="heading"></h2>
                <p class="text-muted lead" v-html="hint"></p>
                <slot></slot>
            </div>
        </div>
    </div>
</template>


<script>

import common from "./Common.js";

export default {
    name: "Heading",
    props: common.props, 

    computed: {
        headingClass() {
            let headingClass = 'h2';

            if(/(h|H)\d/.test(this.classes)) {
                for(let i=0; i<[1, 2, 3, 4, 5].length; i++) {
                    let found = this.classes.match(/\b(h|H)(1|2|3|4|5)/)?.[0];
                    if( found ) {
                        headingClass = found;
                        break;
                    }
                }
            }

            if(/display-\d/.test(this.classes)) {
                for(let i=0; i<[1, 2, 3, 4, 5].length; i++) {
                    let found = this.classes.match(/\bdisplay-(1|2|3|4|5)/)?.[0];
                    if( found ) {
                        headingClass = found;
                        break;
                    }
                }
            }

            return headingClass;
        }
    }
};
</script>