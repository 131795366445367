

export default {
    name: "CommonInput",
    props: {
        heading: String,
        label: String,
        name: String,
        placeholder: String,
        value: [String, Boolean],
        hint: String,
        classes: String,
        readonly: Boolean,
        update: {
            type: Function, 
            default: function() {}
        }
    },
    
    computed: {
        val: {
            get: function() {
                return this.value;
            },
            set: function(newValue) {
                this.update(this.name, newValue);
            },
        },
    },
};