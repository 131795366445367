<template lang="">
    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100 " data-cmp="ProfileMain">
        <AppHeader></AppHeader>
        <section class="flex-fill container-fluid px-0 ">
            <div class="row g-0 mx-0">
                <div class="col-12 d-flex flex-row">
                    <div class="sidebar position-fixed">
                        <Sidebar></Sidebar>
                    </div>
                    <div class="content bg-canvas d-flex flex-column flex-fill">
                        <div class="bg-white border-bottom border-start d-flex flex-row navbar-wrapper px-3 --py-2.5 --shadow-sm">
                            <div class="align-items-center d-flex">
                                <a @click.prevent="$router.back()" class="btn btn-primary btn-sm font-size-16 px-1 rounded-pill">
                                    <span class="material-icons pull-left">
                                    chevron_left
                                    </span>
                                </a>
                            </div>

                            <div>
                                <nav class="ps-3" style="--bs-breadcrumb-divider: '›';" aria-label="breadcrumb">
                                    <ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
                                        <li class="breadcrumb-item">
                                            <router-link class="breadcrumb-item text-decoration-none" :to="$root.links.DASHBOARD">
                                                <span class="">Dashboard</span>
                                            </router-link>
                                        </li>
                                        
                                        <li class="breadcrumb-item">
                                            Settings
                                        </li>

                                        <li class="breadcrumb-item">
                                            {{ currentSection }}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>

                        <div class="overflow-scroll pb-5 pt-5 px-4 px-lg-4 shadow-inset-md">
                            <div class="form-wrapper mx-auto bg-white rounded-5 p-5 shadow-sm">
                                <FormContainer :form="form" :update="update"></FormContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <AppFooter class="mt-5"></AppFooter>
    </div>
</template>
<script>

import {debounce} from "@/plugins/helper.js";
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import FormContainer from "@/components/forms/FormContainer.vue";
import Sidebar from "./Sidebar.vue";
import companyDefinition from "@/components/forms/definitions/Company.js";
import profileDefinition from "@/components/forms/definitions/Profile.js";
import preferencesDefinition from "@/components/forms/definitions/Preferences.js";
import keysDefinition from "@/components/forms/definitions/Keys.js";


export default {
    name: 'SettingsMain', 
    components: {
        AppHeader,
        AppFooter,
        Sidebar,
        FormContainer
    }, 

    data() {
        return {
            profileDefinition: profileDefinition, 
            companyDefinition: companyDefinition, 
            preferencesDefinition: preferencesDefinition,
            keysDefinition: keysDefinition
        }
    }, 


    computed: {

        currentSection() {
            let section = this.$route.params?.section;
            return section.substr(0,1).toUpperCase() + section.substr(1);
        },


        company: {
            get() {
                let data = this.$store.getters.company?.content;
                let definition = this.companyDefinition;

                definition.fields.forEach( (field, i) => {
                    if(typeof data?.company?.fields[field.name] != 'undefined') {
                        definition.fields[i]['value'] = data.company.fields[field.name];
                    }
                });
                
                return definition;
            }, 

            set(definition) {
                console.log(definition);
            }
        },


        profile: {
            get() {
                let userData = this.$store.getters.user;
                let definition = this.profileDefinition;

                definition.fields.forEach( (field, i) => {
                    if(typeof userData?.[field.name] != 'undefined') {
                        definition.fields[i]['value'] = userData[field.name];
                    }
                });
                
                return definition;
            }, 

            set(definition) {
                console.log(definition);
            }
        },

        keys: {
            get() {
                // let data = this.$preferences.get();
                let companyData = this.$store.getters.company?.content;
                let data = companyData?.company?.fields;
                let definition = this.keysDefinition;
                
                definition.fields.forEach( (field, i) => {
                    if(typeof data?.[field.name] != 'undefined') {
                        definition.fields[i]['value'] = data[field.name];
                    }
                });
                
                return definition;
            }, 

            set(definition) {
                console.log(definition);
            }
        },

        preferences: {
            get() {
                let data = this.$preferences.get();
                let definition = this.preferencesDefinition;
                // let deafaultPreferences = {
                //     screen_size: 100
                // };

                // if(!this.$session.get('preferences')) {
                //     data = deafaultPreferences;
                //     this.$session.set('preferences', data);
                // } else {
                //     data = this.$session.get('preferences');
                // }
                
                definition.fields.forEach( (field, i) => {
                    if(typeof data?.[field.name] != 'undefined') {
                        definition.fields[i]['value'] = data[field.name];
                    }
                });
                
                return definition;
            }, 

            set(definition) {
                console.log(definition);
            }
        },
        
        form() {
            return this.formData();
        }

    }, 

    methods: {
        formData() {
            if(this.$route.params.section == 'company') {
                return this.company;
            }

            if(this.$route.params.section == 'profile') {
                return this.profile;
            }

            if(this.$route.params.section == 'keys') {
                return this.keys;
            }

            if(this.$route.params.section == 'preferences') {
                return this.preferences;
            }

            return {};
        },


        update(name, value) {
            let source;
            if(this.$route.params.section == 'company') {
                source = this.company;
            }
            if(this.$route.params.section == 'profile') {
                source = this.profile;
            }

            if(this.$route.params.section == 'keys') {
                source = this.keys;
            }

            if(this.$route.params.section == 'preferences') {
                source = this.preferences;
            }

            source?.fields.forEach(field => {
                if(field.name == name) {
                    field.value = value;
                    this.save(name, value);
                }
            });
        }, 

        save: async ()=>{}, 

        /**
         * Update company information
         */
        async saveCompanyData(name, value) {
            let companyData = this.$store.getters.company?.content;
                companyData.company.fields[name] = value;

            await this.$store.dispatch('updateUserCompany');
        },


        /**
         * Update user profile information
         */
        async saveProfileData(name, value) {
            name, value
        },


        /**
         * Update preferences information
         */
        async savePreferencesData(name, value) {
            // let preferences = this.$preferences.get();

            switch (name) {
                case 'screen_size':
                    value = +value;
                    break;
            
                default:
                    break;
            }

            // preferences[name] = value;

            this.$preferences.setValue(name, value);
        },

        /**
         * Update Keys information
         * 
         * IMPORTANT: Keys (openai_key) will be store in the company data form
         */
        async saveKeysData(name, value) {
            let companyData = this.$store.getters.company?.content;
                companyData.company.fields[name] = value;

            await this.$store.dispatch('updateUserCompany');
        }
    }, 

    beforeMount() {
        let scope = this;
        this.save = debounce(async function(name, value) {
            switch (scope.$route.params?.section) {
                case 'company':
                    await scope.saveCompanyData(name, value);
                    break;

                case 'profile':
                    await scope.saveProfileData(name, value);
                    break;

                case 'keys':
                    await scope.saveKeysData(name, value);
                    break;

                case 'preferences':
                    await scope.savePreferencesData(name, value);
                    break;
            
                default:
                    break;
            }
            
        }, 1000);
    }, 

    async mounted() {
        await this.$store.dispatch('getUserCompany');
    },
}
</script>
<style scoped>
    
.sidebar {
   width: 320px; 
}
.content {
   margin-left: 320px; 
   height: calc(100vh - 86px);
}

.navbar-wrapper {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}
.form-wrapper {
    max-width: 990px;
    min-width: 670px;
}

.rounded-5 {
    border-radius: .5rem!important;
}
</style>