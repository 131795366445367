export default {
    fields: [
        {
            heading: 'Profile',
            name: 'company_user_profile',
            hint: 'Enter your profile information. You may be required to include this information in public communications (e.g. emails). <br/><small class="d-block fs-6 pt-3">Estimated completion time: <span class="text-warning fw-bold">1 minutes</span>.</small>',
            component: 'Header',
            /**
            * classes such us h(1-5) or display-(1-5) will be added to the heading tag
            */
            classes: 'pt-4 h2',
            label: '',
            placeholder: '', 
            value: '',
            readonly: false,
        }, 
        {
            heading: 'What is your first name?',
            label: 'First Name',
            name: 'first_name',
            placeholder: 'First Name', 
            hint: 'e.g. John',
            value: '',
            component: 'InputText', 
            readonly: true
        }, 
        {
            heading: 'What is your last name?',
            label: 'Last Name',
            name: 'last_name',
            placeholder: 'Last Name', 
            hint: 'e.g. Smith',
            value: '',
            component: 'InputText', 
            readonly: true
        }, 
        {
            heading: 'What is your username?',
            label: 'Username',
            name: 'user_login',
            placeholder: 'Enter your user name', 
            hint: 'e.g. jondoe',
            value: '',
            component: 'InputText', 
            readonly: true
        }, 
        {
            heading: 'What is your email address?',
            label: 'Email Address',
            name: 'user_email',
            placeholder: 'Enter your email address', 
            hint: 'e.g. jon@company.com',
            value: '',
            component: 'InputText', 
            readonly: true
        }, 
        {
            heading: 'Neeed a new password?',
            label: 'Password',
            name: 'user_pass',
            placeholder: 'Enter your email address', 
            hint: 'e.g. =@aQOnaSp#1turl6rLcl',
            value: 'Change your password',
            component: 'ButtonController', 
            readonly: false
        }, 
    ]
}