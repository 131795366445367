<template lang="">
    <div id="sidebar">
        <div class="p-2 pt-4">
            <div class="" >
                <h3 class="fs-4 fw-500 mb-0 pb-1 ps-3">
                    <!-- <span class="material-icons-outlined pull-left me-2">
                    settings
                    </span> -->
                    Settings</h3>
                <small class="d-block ps-3 pe-2 mb-3 text-muted">Set up your company infomation, user profile, 
                reset your password, and more.</small>

                <ul class="list-group list-group-flush">
                    <!-- IMPORTANT: disabled items will be hidden (d-none) -->
                    <li v-for="(item, index) in options" :key="index"
                        class="list-group-item position-relative rounded-3 border-0 mt-0 user-select-none"
                        :class="[item.name == $route?.params?.section ? 'active': '', item.disabled? 'disabled d-none pointer-events-none' : '']">
                        <router-link :to="`/settings/${item.name}`" class="stretched-link text-reset text-decoration-none fw-500 font-size-18" @click.prevent="">
                            <IconButton label="" :icon="item.icon" class=""></IconButton>
                            <span class="ms-2">
                                {{item.title}}      
                            </span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import IconButton from "@/components/IconButton.vue";


export default {
    components: {
        IconButton
    },
    
    data() {
        return {
            options: [
                {name: 'profile', title:'Profile', icon: 'person', disabled: false},
                {name: 'company', title:'Company', icon: 'store', disabled: false},
                {name: 'keys', title:'Keys', icon: 'vpn_key', disabled: false},
                {name: 'preferences', title:'Preferences', icon: 'tune', disabled: true},
                {name: 'users', title:'Users', icon: 'group', disabled: true},
                {name: 'subscriptions', title:'Subscription', icon: 'event_available', disabled: true},
                {name: 'billing', title:'Billing', icon: 'receipt', disabled: true},
                {name: 'settings', title:'Settings', icon: 'settings', disabled: true}
            ],
            current: {
                tab: null,
                tabURL: '',
                form: null,
                formName: ''
            },
        }
    },
    computed: {

        // tabs() {
        //     return this.$store.getters.tabs;
        // }, 
    }
}
</script>
<style scoped>
    
.nav-item {
    width: 33%;
}

.list-group-item {
    cursor: pointer;
    margin-bottom: 2px;
    transition: all 0.2s;
}
.list-group-item.active {
    background-color: var(--primary-light) !important;
}
.list-group-item:not(.active):hover {
    background-color: var(--primary-lighter) !important;
}
.list-group-item.active a {
    color: var(--text-main) !important;
}
.list-group-item.active .btn-icon {
    background-color: var(--primary) !important;
    color: #ffffff !important;
}

/* completed */
.list-group-item.active.completed {
    background-color: hsl(134 61% 41%/.2)!important;
    color: var(--success)!important;
}
.list-group-item.completed:hover {
    background-color: hsl(134 61% 41%/.3)!important;
}
.list-group-item.completed > a {
    color: var(--bs-success)!important;
}
.list-group-item.completed .btn-icon {
    background-color: var(--success)!important;
    color: var(--bs-white)!important;
}

/* disabled */
.list-group-item.disabled {
    opacity: 0.5;
}
</style>